import { useNewoContext } from '@isyc-react/newo/modules/context';
import { academicRecordOptionId, campusOnlineOptionId, enrollmentOptionId, preregistrationOptionId, userProfileOptionId } from 'common-app/constants';
import { switchRoutes } from 'core/router/router';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AcademicRecordScene, ErrorScene } from 'scenes';
import { EnrollmentsScene } from 'scenes/enrollments';
import { EnrollmentDetailScene } from 'scenes/enrollments/enrollments-detail.scene';
import { PreregistrationScene } from 'scenes/preregistration';
import { PreregistrationDetailScene } from 'scenes/preregistration/preregistration-detail.scene';
import { StudentCoursesScene } from 'scenes/student-courses/student-courses.scene';
import { UserSettingsScene } from 'scenes/user-settings';
const useLoggedRouterRedirect = () => {
    const { hasAcademicRecords, canAccessSite } = useNewoContext();
    if (hasAcademicRecords === true) {
        if (canAccessSite(academicRecordOptionId)) {
            return React.createElement(Navigate, { to: switchRoutes.academicRecord });
        }
    }
    else {
        if (canAccessSite(preregistrationOptionId)) {
            return React.createElement(Navigate, { to: switchRoutes.preregistration.root });
        }
    }
    return React.createElement(Navigate, { to: switchRoutes.error });
};
export const RouterLogged = () => {
    const location = useLocation();
    const { hasAcademicRecords, canAccessSite } = useNewoContext();
    const loggedRouterRedirect = useLoggedRouterRedirect();
    return (React.createElement(React.Fragment, null,
        React.createElement(Routes, { location: location },
            React.createElement(Route, { path: '', element: loggedRouterRedirect }),
            canAccessSite(academicRecordOptionId) && hasAcademicRecords === true &&
                React.createElement(Route, { path: switchRoutes.academicRecord, element: React.createElement(AcademicRecordScene, null) }),
            canAccessSite(preregistrationOptionId) &&
                React.createElement(Route, { path: switchRoutes.preregistration.detail, element: React.createElement(PreregistrationDetailScene, null) }),
            canAccessSite(preregistrationOptionId) &&
                React.createElement(Route, { path: switchRoutes.preregistration.root, element: React.createElement(PreregistrationScene, null) }),
            canAccessSite(enrollmentOptionId) && hasAcademicRecords === true &&
                React.createElement(Route, { path: switchRoutes.enrollment.detail, element: React.createElement(EnrollmentDetailScene, null) }),
            canAccessSite(enrollmentOptionId) && hasAcademicRecords &&
                React.createElement(Route, { path: switchRoutes.enrollment.root, element: React.createElement(EnrollmentsScene, null) }),
            canAccessSite(campusOnlineOptionId) && hasAcademicRecords === true &&
                React.createElement(Route, { path: switchRoutes.studentCourses, element: React.createElement(StudentCoursesScene, null) }),
            React.createElement(Route, { path: switchRoutes.error, element: React.createElement(ErrorScene, null) }),
            canAccessSite(userProfileOptionId) && hasAcademicRecords === true &&
                React.createElement(Route, { path: switchRoutes.userSettings, element: React.createElement(UserSettingsScene, null) }),
            React.createElement(Route, { path: '*', element: loggedRouterRedirect }))));
};

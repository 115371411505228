import { gql, useApolloClient, useMutation, } from '@apollo/client';
const USER_SETTINGS = gql `
  mutation SignatureRequest($user_settings_dto: PortalInfirmorumUserSettingsDTOInput) {
    infirmorum_user_settings_update(user_settings_dto: $user_settings_dto)
  }
`;
export const useUserSettingsUpdateMutation = (options) => useMutation(USER_SETTINGS, options);
export const useUserSettingsUpdateMutationPromise = () => {
    const client = useApolloClient();
    return (variables, options) => client.mutate(Object.assign(Object.assign({}, options), { mutation: USER_SETTINGS, variables }));
};

import { Spinner } from "@isyc-react/design/bootstrap";
import { PasswordChangeForm } from "@isyc-react/newo/modules/pods/user-settings";
import { useGetMyDataQuery } from "@isyc-react/oc-api";
import React from "react";
import { UserSettingsForm } from "./user-settings.form";
import { passRegExp } from "common-app/constants";
export const UserSettingsContainer = () => {
    const { data, loading, error } = useGetMyDataQuery();
    const checkString = React.useCallback((str) => passRegExp.test(str), []);
    const myData = React.useMemo(() => {
        if (data) {
            return data.get_my_data;
        }
        return null;
    }, [data]);
    if (loading || !myData) {
        return (React.createElement("div", { className: 'd-flex justify-content-center' },
            React.createElement(Spinner, { variant: "primary" })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(UserSettingsForm, { myData: myData }),
        React.createElement("h4", { className: "border-bottom border-primary text-primary pb-1" }, "Contrase\u00F1a"),
        React.createElement(PasswordChangeForm, { checkString: checkString })));
};

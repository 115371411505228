import { Collapse } from '@isyc-react/design/bootstrap';
import logo from 'assets/img/logo.png';
import clsx from 'clsx';
import React from 'react';
import { UserPictureComponent, UserPictureSize } from '@isyc-react/oncustomer/modules/objects/user';
import { useNavigate } from 'react-router-dom';
import { SidebarOptions } from './sidebar-options.component';
import '/assets/css/menu.css';
export const SidebarComponent = props => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (open)
            document.body.classList.add('overflow-hidden');
        else
            document.body.classList.remove('overflow-hidden');
    }, [open]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row wrapper p-0" },
            React.createElement("div", { className: "bar" },
                React.createElement("div", { className: "start" },
                    React.createElement("div", { className: "row mx-auto justify-content-center" },
                        React.createElement("div", { className: "col-auto logo cursor-pointer" },
                            React.createElement("div", { className: 'cursor-pointer', onClick: () => navigate('/') },
                                React.createElement("img", { src: logo }))),
                        React.createElement("div", { className: "avatar d-none d-lg-block" },
                            React.createElement(UserPictureComponent, { size: UserPictureSize.BG, text: {}, image: {} }),
                            React.createElement("hr", { className: "border-2 border-bottom border-grey opacity-50" })))),
                React.createElement(Collapse, { in: open },
                    React.createElement("div", { id: 'collapsingNavbar', className: 'menu-collapse' },
                        React.createElement(SidebarOptions, { onEntryClick: () => setOpen(false) }))),
                React.createElement("div", { className: 'button navbar-light' },
                    React.createElement("button", { className: clsx('navbar-toggler border-0', { collapsed: !open }), type: 'button', onClick: () => setOpen(!open), "aria-expanded": open },
                        React.createElement("span", { className: 'toggler-icon top-bar' }),
                        React.createElement("span", { className: 'toggler-icon middle-bar' }),
                        React.createElement("span", { className: 'toggler-icon bottom-bar' })))))));
};

import { namespaces } from "i18n/i18n.constants";
import React from "react";
import { useTranslation } from "react-i18next";
export const useAcademicProgramTableColumns = () => {
    const { t } = useTranslation(namespaces.common);
    const columns = React.useMemo(() => [
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.codeLabel').toString(),
            accessor: 'subjectCode',
            aggregate: 'count',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            width: 305,
            minWidth: 305,
            Header: t('infirmorum.academicRecord.table.courseLabel').toString(),
            accessor: 'subjectName',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.courseNumberLabel').toString(),
            accessor: 'course',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", { className: "text-center" }, value),
        },
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.creditsLabel').toString(),
            accessor: 'subjectCredits',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", { className: "text-center" }, value),
        },
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.examCallLabel').toString(),
            accessor: 'type',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value !== null && value !== void 0 ? value : '-'),
        },
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.scoreLabel').toString(),
            accessor: 'qualification',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", { className: "text-center" }, value !== null && value !== void 0 ? value : '-'),
        },
        {
            width: 200,
            minWidth: 200,
            Header: t('infirmorum.academicRecord.table.scoreTextLabel').toString(),
            accessor: 'qualificationLabel',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value !== null && value !== void 0 ? value : '-'),
        },
        {
            width: 25,
            minWidth: 25,
            Header: t('infirmorum.academicRecord.table.dateLabel').toString(),
            accessor: 'trainingPeriodName',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", { className: "text-center" }, value !== null && value !== void 0 ? value : '-'),
        },
        {
            id: 'examDate',
            accessor: 'examDate',
        }
    ], []);
    return columns;
};

import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import React from 'react';
import { usePreregistrationTableColumns } from './preregistration-table-columns';
export const PreregistrationTableComponent = props => {
    const { data, onCancel, onDetail } = props;
    const columns = usePreregistrationTableColumns({
        onCancel,
        onDetail
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(TableClient, { components: {
                Filter: (props) => React.createElement(React.Fragment, null),
                Table: (props) => React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "table-responsive-xxl table-list table-coreelearning" },
                        React.createElement(Components.Table, Object.assign({}, props)))),
                Pagination: (props) => React.createElement(React.Fragment, null)
            }, summary: 'Se visualiza una tabla que muestra preinscripciones', disableGlobalFilter: true, disableFilters: true, columns: columns, data: data, initialState: {
                pageSize: 500,
            } })));
};

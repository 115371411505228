import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const Footer = (props) => {
    const { containerCssClass = "container p-0 bg-primary" } = props;
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("footer", null,
            React.createElement("div", { className: containerCssClass },
                React.createElement("ul", { className: "list-group list-group-horizontal-xl d-flex justify-content-center align-items-center my-3 my-xl-0" },
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/aviso-legal/" }, "Aviso legal")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 d-none d-xl-block" },
                        React.createElement("span", { className: "text-white" }, "|")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/politica-de-privacidad/" }, "Pol\u00EDtica de privacidad")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 d-none d-xl-block" },
                        React.createElement("span", { className: "text-white" }, "|")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/politica-de-cookies/" }, "Pol\u00EDtica de cookies")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 d-none d-xl-block" },
                        React.createElement("span", { className: "text-white" }, "|")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/wp-content/uploads/2021/11/Estatutos-asociacion.pdf" }, "Estatutos")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 d-none d-xl-block" },
                        React.createElement("span", { className: "text-white" }, "|")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/codigo-de-conducta/" }, "C\u00F3digo de conducta")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 d-none d-xl-block" },
                        React.createElement("span", { className: "text-white" }, "|")),
                    React.createElement("li", { className: "list-group-item border-0 px-2 text-center" },
                        React.createElement("a", { className: "text-white text-decoration-underline-hover", href: "https://saluscampusdemadrid.com/trabaja-con-nosotros/" }, "Trabaja con nosotros")))))));
};

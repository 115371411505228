import { useLogout } from '@isyc-react/auth/modules/pods/logout';
import { useNewoContext } from '@isyc-react/newo/modules/context';
import clsx from 'clsx';
import { academicRecordOptionId, campusOnlineOptionId, enrollmentOptionId, preregistrationOptionId, userProfileOptionId } from 'common-app/constants';
import { routes, switchRoutes } from 'core/router/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
export const SidebarOptions = (props) => {
    const { onEntryClick } = props;
    const { logout } = useLogout();
    const { pathname } = useLocation();
    const { t } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const { hasAcademicRecords, canAccessSite } = useNewoContext();
    const redirect = React.useCallback((route) => {
        navigate(route);
        onEntryClick && onEntryClick();
    }, [history, onEntryClick]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "menu collapse show", id: "collapsingMenu" },
            React.createElement("ul", { className: 'nav flex-column mb-auto mt-4' },
                canAccessSite(preregistrationOptionId) &&
                    React.createElement("li", { className: clsx('nav-item preregistration', { active: pathname == switchRoutes.preregistration.root || pathname == switchRoutes.preregistration.detail }) },
                        React.createElement("a", { className: clsx('nav-link cursor-pointer'), onClick: (e) => {
                                e.preventDefault();
                                redirect(routes.preregistration.root);
                            } },
                            React.createElement("div", { className: "icon" }),
                            t('infirmorum.menu.preregistrations').toUpperCase())),
                canAccessSite(enrollmentOptionId) && hasAcademicRecords === true &&
                    React.createElement("li", { className: clsx('nav-item enrollment', { active: pathname == switchRoutes.enrollment.root || pathname == switchRoutes.enrollment.detail }) },
                        React.createElement("a", { className: clsx('nav-link cursor-pointer'), onClick: (e) => {
                                e.preventDefault();
                                redirect(routes.enrollment.root);
                            } },
                            React.createElement("div", { className: "icon" }),
                            t('infirmorum.menu.enrollments').toUpperCase())),
                canAccessSite(academicRecordOptionId) && hasAcademicRecords === true &&
                    React.createElement("li", { className: clsx('nav-item academicRecord', { active: pathname == switchRoutes.academicRecord }) },
                        React.createElement("a", { className: clsx('nav-link cursor-pointer'), onClick: (e) => {
                                e.preventDefault();
                                redirect(routes.academicRecord);
                            } },
                            React.createElement("div", { className: "icon" }),
                            t('infirmorum.menu.academicRecord').toUpperCase())),
                canAccessSite(campusOnlineOptionId) && hasAcademicRecords === true &&
                    React.createElement("li", { className: clsx('nav-item studentCourses', { active: pathname == switchRoutes.studentCourses }) },
                        React.createElement("a", { className: clsx('nav-link cursor-pointer'), onClick: (e) => {
                                e.preventDefault();
                                redirect(routes.studentCourses);
                            } },
                            React.createElement("div", { className: "icon" }),
                            t('infirmorum.menu.courses').toUpperCase())),
                canAccessSite(userProfileOptionId) &&
                    React.createElement("li", { className: clsx('nav-item userSettings', { active: pathname == routes.userSettings }) },
                        React.createElement("a", { className: clsx('nav-link cursor-pointer'), onClick: (e) => {
                                e.preventDefault();
                                redirect(routes.userSettings);
                            } },
                            React.createElement("div", { className: "icon" }),
                            t('infirmorum.menu.profile').toUpperCase()))),
            React.createElement("div", { className: "end flex-column d-flex" },
                React.createElement("hr", { className: 'border-2 border-bottom border-grey opacity-50 w-100' }),
                React.createElement("ul", { className: 'nav' },
                    React.createElement("li", { className: "nav-item logout" },
                        React.createElement("a", { className: 'nav-link cursor-pointer', onClick: () => logout() },
                            React.createElement("div", { className: "icon" }),
                            React.createElement("span", null, t('infirmorum.menu.exit').toString()))))))));
};

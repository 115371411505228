//TODO: Move to newo library and remove from the three portals
export var IdentityDocumentType;
(function (IdentityDocumentType) {
    IdentityDocumentType["DNI"] = "1";
    IdentityDocumentType["NIE"] = "2";
    IdentityDocumentType["CIF"] = "3";
    IdentityDocumentType["PASSPORT"] = "4";
    IdentityDocumentType["TIE"] = "5";
    IdentityDocumentType["FOREIGN_DNI"] = "6";
    IdentityDocumentType["MINOR"] = "7";
})(IdentityDocumentType || (IdentityDocumentType = {}));
export var CaptchaType;
(function (CaptchaType) {
    CaptchaType[CaptchaType["IMAGE"] = 0] = "IMAGE";
    CaptchaType[CaptchaType["AUDIO"] = 1] = "AUDIO";
})(CaptchaType || (CaptchaType = {}));

import { namespaces } from 'i18n/i18n.constants';
import { SidebarHeaderFooterLayout } from 'layout';
import { UserSettingsContainer } from 'pods/user-settings';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const UserSettingsScene = () => {
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(SidebarHeaderFooterLayout, { headerTitle: t('infirmorum.userSettings.title'), className: 'userSettings' },
        React.createElement(UserSettingsContainer, null)));
};

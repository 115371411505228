import defaultCourseImg from 'assets/img/course-default.png';
import { CardCourseComponent, ImageModeToUse } from '@isyc-react/newo/modules/pods/courses/card';
import { useOpenMoodleLink } from '@isyc-react/newo/modules/utils/open-moodle-link';
import { useArchiveFileReadQueryPromise } from '@isyc-react/oc-api';
import clsx from 'clsx';
import React from 'react';
export const InfirmorumEnrollmentCourseStructureCardComponent = props => {
    const { enrollmentCourse, card = {} } = props;
    const openMoodleLink = useOpenMoodleLink();
    const { onClick = () => openMoodleLink('/course/view.php', 'id=' + enrollmentCourse.courseMoodleId) } = card;
    const useFile = useArchiveFileReadQueryPromise();
    const [image, setImage] = React.useState();
    //Load image by id
    React.useEffect(() => {
        if (enrollmentCourse.courseImage) {
            useFile({ id: enrollmentCourse.courseImage }).then(r => {
                if (r.data && r.data.archive_readFile) {
                    setImage(r.data.archive_readFile);
                }
            });
        }
        else {
            setImage(undefined);
        }
    }, [enrollmentCourse.courseImage]);
    const data = React.useMemo(() => {
        return {
            id: enrollmentCourse.courseId,
            name: enrollmentCourse.courseName,
            moodleId: enrollmentCourse.courseMoodleId
        };
    }, []);
    return (React.createElement(CardCourseComponent, { className: 'col-12 col-md-6 col-xxl-4 p-4', cardClassName: 'bg-transparent text-white border-secondary rounded-5 border-2', data: data, onClick: () => {
            onClick && onClick(data);
        }, Components: {
            Header: p => (React.createElement(InfirmorumCardCourseHeaderComponent, Object.assign({}, p, { imageModeToUse: image ? ImageModeToUse.BASE64 : undefined, imageBase64: image === null || image === void 0 ? void 0 : image.content }))),
            Body: p => (React.createElement(InfirmorumCardCourseBodyComponent, Object.assign({}, p)))
        } }));
};
const InfirmorumCardCourseHeaderComponent = props => {
    const { imageBase64, imagePath = defaultCourseImg, imageModeToUse = ImageModeToUse.PATH, } = props;
    const backgroundImageVal = React.useMemo(() => {
        switch (imageModeToUse) {
            case ImageModeToUse.PATH:
                return `url(${imagePath})`;
            case ImageModeToUse.BASE64:
                return 'url("data:image/png;base64,' + imageBase64 + '")';
        }
    }, [imageModeToUse, imagePath, imageBase64]);
    return (React.createElement("div", { className: clsx('card-bg cursor-pointer'), style: { backgroundImage: backgroundImageVal } }));
};
const InfirmorumCardCourseBodyComponent = props => {
    const { data, onClick } = props;
    const { name } = data;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: 'btn btn-link p-0', disabled: onClick == undefined, onClick: () => onClick && onClick(data) },
            React.createElement("span", { className: 'card-title font-size-20' }, name))));
};

import React from 'react';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import { useAcademicProgramTableColumns } from './academic-program-table-columns';
export const AcademicProgramTableComponent = (props) => {
    const { data } = props;
    const columns = useAcademicProgramTableColumns();
    return (React.createElement(React.Fragment, null,
        React.createElement(TableClient, { components: {
                Filter: (props) => React.createElement(React.Fragment, null),
                Table: (props) => React.createElement(React.Fragment, null,
                    React.createElement("div", null,
                        React.createElement("div", { className: "table-responsive-xxl table-list table-coreelearning" },
                            React.createElement(Components.Table, Object.assign({}, props))))),
                Pagination: (props) => React.createElement(React.Fragment, null)
            }, disableGlobalFilter: true, disableFilters: true, columns: columns, data: data, summary: 'Se visualiza una tabla que muestra expedientes', initialState: {
                hiddenColumns: [
                    "examDate"
                ],
                pageSize: 500,
                sortBy: [
                    { id: 'course', desc: true },
                    { id: 'subjectName', desc: false },
                    { id: 'periodName', desc: true },
                    { id: 'examDate', desc: true }
                ]
            } })));
};

import React from 'react';
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { useGetAcademicRecords } from '@isyc-react/newo/modules/common/api/graphql';
import { AcademicProgramDownloadPdf } from '@isyc-react/newo/modules/pods/academic-program/academic-program-download-pdf.component';
import { AcademicRecordProgramSelectorComponent } from '@isyc-react/newo/modules/pods/academic-record/academic-record-program-selector.component';
import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import { AcademicProgramTableComponent } from 'pods/academic-program';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const AcademicRecordContainer = props => {
    var _a;
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const [currentProgramRecord, setCurrentProgramRecord] = React.useState();
    const { data, loading, error, } = useGetAcademicRecords({ variables: { user_id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : 0 } });
    const tableData = React.useMemo(() => {
        var _a;
        return (_a = currentProgramRecord === null || currentProgramRecord === void 0 ? void 0 : currentProgramRecord.programRecordLineList.map(entry => ({
            course: entry.course,
            qualification: entry.qualification,
            qualificationLabel: entry.qualificationLabel,
            subjectCode: entry.subjectCode,
            subjectCredits: entry.subjectCredits,
            subjectName: entry.subjectName,
            trainingPeriodName: entry.trainingPeriodName,
            type: entry.examCallTypeLevel1Label,
            examDate: entry.examDate
        }))) !== null && _a !== void 0 ? _a : [];
    }, [currentProgramRecord]);
    React.useEffect(() => {
        if (error) {
            navigate(routes.error);
        }
    }, [error]);
    if (loading || !data) {
        return React.createElement(Spinner, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row w-100 align-items-center m-0 mb-5" },
            React.createElement("div", { className: "col-md p-0" },
                React.createElement("h2", { className: 'text-primary font-size-22' },
                    t('infirmorum.academicRecord.listLabel'),
                    " (",
                    data.programRecord_getListByStudent.programRecordList.length,
                    ")"))),
        React.createElement(AcademicRecordProgramSelectorComponent, { programRecordList: data.programRecord_getListByStudent.programRecordList, onChange: (val) => {
                setCurrentProgramRecord(val);
            } }),
        currentProgramRecord &&
            React.createElement(React.Fragment, null,
                React.createElement(AcademicProgramTableComponent, { data: tableData }),
                React.createElement(AcademicProgramDownloadPdf, { programRecordId: currentProgramRecord.ID, Runner: DownloadAcademicProgramPdfRunner }))));
};
const DownloadAcademicProgramPdfRunner = props => {
    const { download, isDownloading } = props;
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "text-center" },
                React.createElement("button", { disabled: isDownloading, type: 'button', className: "btn btn-pill btn-lg btn-primary mx-auto d-block my-5", onClick: download },
                    t('infirmorum.academicRecord.download'),
                    isDownloading ? React.createElement(Spinner, { animation: 'border', className: 'ms-2 d-flex justify-content-center', size: 'sm' }) : React.createElement(React.Fragment, null))))));
};

import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const usePreregistrationTableColumns = (props) => {
    const { onCancel, onDetail } = props;
    const { t } = useTranslation(namespaces.common);
    const columns = React.useMemo(() => [
        {
            Header: t('infirmorum.preregistration.table.columns.id').toString(),
            accessor: 'id',
            aggregate: 'count',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('infirmorum.preregistration.table.columns.trainingProgram').toString(),
            accessor: 'trainingProgramName',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('infirmorum.preregistration.table.columns.date').toString(),
            accessor: 'creationTimestamp',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, new Date(value).toLocaleDateString("es-ES", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })),
        },
        {
            Header: t('infirmorum.preregistration.table.columns.state').toString(),
            accessor: 'stateLabel',
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: "",
            id: "Detail",
            aggregate: 'uniqueCount',
            disableSortBy: true,
            accessor: content => ({
                id: content.id,
                stateCode: content.stateCode
            }),
            Cell: ({ value }) => {
                if (value.stateCode !== 'CANCELADO') {
                    return (React.createElement("div", null,
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-outline-lighter-secondary btn-pill text-nowrap px-1", onClick: () => { onDetail && onDetail(value.id); } },
                            React.createElement("i", { className: "mdi mdi-information-outline font-size-20" }),
                            React.createElement("span", { className: "mx-3 font-size-13" }, t('infirmorum.preregistration.table.seeDetail').toUpperCase()))));
                }
                return (React.createElement(React.Fragment, null));
            }
        },
        {
            Header: "",
            id: "Cancel",
            aggregate: 'uniqueCount',
            disableSortBy: true,
            accessor: content => ({
                id: content.id,
                stateCode: content.stateCode
            }),
            Cell: ({ value }) => {
                if (value.stateCode === 'BORRADOR') {
                    return (React.createElement("div", null,
                        React.createElement("button", { type: "button", className: "btn btn-sm btn-outline-lighter-secondary btn-pill text-nowrap", style: { padding: "0rem 5px 0rem 5px" }, onClick: () => { onCancel && onCancel(value.id); } },
                            React.createElement("i", { className: "mdi mdi-delete-outline font-size-20" }))));
                }
                return null;
            }
        }
    ], []);
    return columns;
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languages, namespaces } from './i18n.constants';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { preloadNamespaces } from './i18n-preload';
const defaultLanguage = languages.es;
const createI18n = (language) => {
    const i18n = i18next.use(Backend).use(LanguageDetector).use(initReactI18next);
    i18n.init({
        load: 'languageOnly',
        fallbackLng: language,
        lowerCaseLng: true,
        ns: [...preloadNamespaces, namespaces.common],
        backend: {
            loadPath: `${process.env.NODE_ENV === 'production' && process.env.public_path_resources
                ? process.env.public_path_resources
                : '/'}locales/{{lng}}/{{ns}}.json`,
        },
    });
    return i18n;
};
export const i18n = createI18n(defaultLanguage);

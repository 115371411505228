import { CreateStudentDossierFromTemplateContainer } from '@isyc-react/newo/modules/pods/dossier';
import { useDossierUpdateStatusMutationPromise } from '@isyc-react/oc-api/queries/dossier/dossier-update-status';
import { parameter_trainingProgramLabelName } from 'common-app/constants';
import { routes } from 'core/router';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PreregistrationTableComponent } from './preregistration-table.component';
export const PreregistrationContainer = props => {
    const { data, onCancelPreregistration } = props;
    const navigate = useNavigate();
    const useDossierUpdateStatusPromise = useDossierUpdateStatusMutationPromise();
    const tableData = React.useMemo(() => data.map((sourceObject) => {
        var _a;
        return ({
            id: sourceObject.id,
            trainingProgramName: ((_a = sourceObject.parameters.find(x => x.label == parameter_trainingProgramLabelName)) === null || _a === void 0 ? void 0 : _a.comboCodeLabel) || "",
            creationTimestamp: sourceObject.creationTimestamp,
            stateLabel: sourceObject.treeWorkFlowMap.level1.label,
            stateCode: sourceObject.treeWorkFlowMap.level1.code
        });
    }), [data]);
    const onDetail = (id) => {
        navigate(routes.preregistration.detail(id.toString()));
    };
    const onCancel = (id) => {
        useDossierUpdateStatusPromise({ dossierId: id, status: { treelevel1: 'CANCELADO' } }).then((value) => {
            onCancelPreregistration();
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "col-md-auto p-0 pt-3 pt-md-0" },
            React.createElement(CreateStudentDossierFromTemplateContainer, { templateCode: 'INSCR_NUEVOS', participantTemplateCode: 'ALUMNO', createDossierInput: {
                    businessUnitId: 5100,
                    statusTreeLevel1: 'BORRADOR'
                } })),
        React.createElement(PreregistrationTableComponent, { data: tableData, onCancel: onCancel, onDetail: onDetail })));
};

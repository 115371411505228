import { AcademicRecordContainer } from 'pods/academic-record';
import React from 'react';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { SidebarHeaderFooterLayout } from 'layout';
export const AcademicRecordScene = () => {
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(SidebarHeaderFooterLayout, { headerTitle: t('infirmorum.menu.academicRecord'), className: 'academicRecord' },
        React.createElement(AcademicRecordContainer, null)));
};

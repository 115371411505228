import { Slug } from '@isyc-react/animation';
import { getCheckPwdRoute } from '@isyc-react/auth/modules/base';
import { useLogin } from '@isyc-react/auth/modules/pods/login';
import { LoginActionStatus } from '@isyc-react/auth/modules/pods/login/login.vm';
import { Spinner } from '@isyc-react/design/bootstrap';
import '@isyc-react/design/main';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { useForm } from '@isyc-react/form/modules/rhf';
import { AuthErrorCode } from '@isyc-react/oc-api/auth';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
export const LoginForm = props => {
    const { onSuccess, onError, onRestore, onSuccessRedirectInitialLocation = true, buCode, } = props;
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { login } = useLogin();
    const { t } = useTranslation(namespaces.common);
    const { search } = useLocation();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const buCodeUri = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const buCodeParam = params.get('buCode');
        return buCodeParam ? decodeURIComponent(buCodeParam) : 'SHARE';
    }, [search]);
    const onSubmit = form => {
        setLoading(true);
        const bu = buCode !== null && buCode !== void 0 ? buCode : buCodeUri;
        login(Object.assign(Object.assign({}, form), { bu }), onSuccessRedirectInitialLocation)
            .then(res => {
            setLoading(false);
            setStatus(LoginActionStatus.SUCCESS);
            onSuccess && onSuccess(res.user);
        })
            .catch((e) => {
            var _a;
            // TODO show different errors checking error code (FEEDBACK)
            setLoading(false);
            if (e.response && e.response.data.code === AuthErrorCode.USER_CHANGE_PWD_ERROR) {
                navigate(getCheckPwdRoute(form.username, bu));
            }
            setStatus(LoginActionStatus.ERROR);
            onError && onError((_a = e.response) === null || _a === void 0 ? void 0 : _a.data);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0, 40px, 0)' } },
                React.createElement("div", { className: "mb-4" },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: { control, name: 'username', rules: { required: true } }, input: {
                            size: undefined,
                            placeholder: t('infirmorum.login.email.placeholder'),
                            label: t('infirmorum.login.email').toUpperCase()
                        }, errorMessage: 'El campo es obligatorio' })),
                React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: { control, name: 'password', rules: { required: true } }, input: {
                        size: undefined,
                        placeholder: t('infirmorum.login.password.placeholder'),
                        label: t('infirmorum.login.password').toUpperCase()
                    }, errorMessage: 'El campo es obligatorio' }),
                status && status === LoginActionStatus.ERROR && (React.createElement(Slug, null,
                    React.createElement("p", { className: 'text-danger text-center mt-3' }, t('infirmorum.login.loginError')))),
                React.createElement("div", { className: 'd-flex justify-content-center my-5' },
                    React.createElement("button", { className: 'btn btn-pill btn-lg btn-primary d-block mt-4', type: 'submit', disabled: loading },
                        t('infirmorum.login.login'),
                        loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' })))))));
};

import { Spinner } from '@isyc-react/design/bootstrap';
import { NEWO_DOSSIER_READ } from '@isyc-react/newo/modules/fragments';
import { useDossierReadLazyQuery } from '@isyc-react/oc-api';
import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import { SidebarHeaderFooterLayout } from 'layout';
import { PreregistrationDetailContainer } from 'pods/preregistration/detail/preregistration-detail-container';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
export const PreregistrationDetailScene = () => {
    const { t } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const { dossierId } = useParams();
    const [useDossierById, { data, loading, error }] = useDossierReadLazyQuery({ fragment: NEWO_DOSSIER_READ, fragmentName: 'NewoDossierRead' });
    const dossierCallback = React.useCallback(() => {
        if (dossierId && dossierId !== '0') {
            useDossierById({ variables: { id: parseInt(dossierId) } });
        }
    }, [dossierId]);
    React.useEffect(() => dossierCallback(), [dossierId]);
    const dossier = React.useMemo(() => {
        if (data === null || data === void 0 ? void 0 : data.dossier_read) {
            //Forbid accessing a cancelled dossier
            if (data.dossier_read.treeWorkFlowMap.level1.code === 'CANCELADO') {
                navigate(routes.error);
            }
            return data === null || data === void 0 ? void 0 : data.dossier_read;
        }
        return null;
    }, [data]);
    React.useEffect(() => {
        if (error) {
            navigate(routes.error);
        }
    }, [error]);
    if (!dossier) {
        return (React.createElement("div", { className: 'd-flex justify-content-center' },
            React.createElement(Spinner, { variant: "primary" })));
    }
    return (React.createElement(SidebarHeaderFooterLayout, { headerTitle: t('infirmorum.preregistration.title'), className: 'preregistration' },
        React.createElement(PreregistrationDetailContainer, { dossier: dossier, reloadDossier: dossierCallback })));
};

import { authSwitchRoutes } from '@isyc-react/auth/modules/base';
import { generatePath } from 'react-router-dom';
export const switchRoutes = Object.assign(Object.assign({}, authSwitchRoutes), { root: '/', academicRecord: '/academicRecord', preregistration: {
        root: '/preregistration',
        detail: '/preregistration/detail/:dossierId',
    }, enrollment: {
        root: '/enrollment',
        detail: '/enrollment/detail/:dossierId'
    }, studentCourses: '/studentCourses', error: '/error', login: "/login", register: "/register", userSettings: "/userSettings" });
export const routes = Object.assign(Object.assign({}, switchRoutes), { preregistration: Object.assign(Object.assign({}, switchRoutes.preregistration), { detail: dossierId => generatePath(switchRoutes.preregistration.detail, { dossierId }) }), enrollment: Object.assign(Object.assign({}, switchRoutes.enrollment), { detail: dossierId => generatePath(switchRoutes.enrollment.detail, { dossierId }) }) });

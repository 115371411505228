import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { useDossierByPersonAndTypeReadLazyQuery } from '@isyc-react/newo/modules/common/api/graphql';
import { namespaces } from 'i18n/i18n.constants';
import { SidebarHeaderFooterLayout } from 'layout';
import { PreregistrationContainer } from 'pods/preregistration';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
export const PreregistrationScene = () => {
    const { t } = useTranslation(namespaces.common);
    const { user } = useAuthContext();
    const [useDossierByPersonAndTypeLazy, { data, loading, error }] = useDossierByPersonAndTypeReadLazyQuery();
    useEffect(() => {
        var _a, _b;
        useDossierByPersonAndTypeLazy({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0, types: ['INSCR'] } });
    }, []);
    const onCancelPreregistration = () => {
        var _a, _b;
        useDossierByPersonAndTypeLazy({ variables: { personId: (_b = (_a = user === null || user === void 0 ? void 0 : user.person) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0, types: ['INSCR'] } });
    };
    return (React.createElement(SidebarHeaderFooterLayout, { headerTitle: t('infirmorum.preregistration.title'), className: 'preregistration' }, (loading || !data) ?
        React.createElement("div", { className: 'd-flex justify-content-center' },
            React.createElement(Spinner, { variant: "primary" }))
        :
            React.createElement(PreregistrationContainer, { data: data.dossier_readByPersonAndTypes, onCancelPreregistration: onCancelPreregistration })));
};

import { AuthStatus, useAuthContext } from '@isyc-react/auth/modules/context';
import { NewoProvider } from '@isyc-react/newo/modules/context';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RouterLogged } from './logged';
import { RouterUnlogged } from './unlogged';
const basename = '/infirmorum/';
export const Router = () => {
    if (window.location.pathname === '/') {
        window.location.replace(basename);
    }
    return (React.createElement(BrowserRouter, { basename: basename },
        React.createElement(RouterSwitch, null)));
};
const RouterSwitch = props => {
    const { status } = useAuthContext();
    switch (status) {
        case AuthStatus.LOADING:
            return (React.createElement("div", { className: 'd-flex align-items-center justify-content-center col-12' },
                React.createElement("div", { className: 'spinner-border text-primary mt-5', role: 'status' },
                    React.createElement("span", { className: 'visually-hidden' }, "Loading..."))));
        case AuthStatus.LOGGED:
            return (React.createElement(NewoProvider, null,
                React.createElement(RouterLogged, null)));
    }
    return React.createElement(RouterUnlogged, null);
};

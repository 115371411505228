import { Slides } from '@isyc-react/animation';
import { routes, switchRoutes } from 'core/router/router';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { LoginScene } from 'scenes';
export const RouterUnlogged = () => {
    const location = useLocation();
    return (React.createElement(Routes, { location: location },
        React.createElement(Route, { path: switchRoutes.login, element: React.createElement(Slides, { items: [
                    {
                        key: 0,
                        slide: () => (React.createElement(LoginScene, null))
                    },
                    /*{
                      key: 1,
                      slide: () => (<RegisterScene />)
                    }*/
                ], 
                //current={location.pathname.includes(switchRoutes.login) ? 0 : 1}
                current: location.pathname.includes(switchRoutes.login) ? 0 : 0 }) }),
        React.createElement(Route, { path: switchRoutes.register, element: React.createElement(Slides, { items: [
                    {
                        key: 0,
                        slide: () => (React.createElement(LoginScene, null))
                    },
                    /*{
                      key: 1,
                      slide: () => (<RegisterScene />)
                    }*/
                ], 
                //current={location.pathname.includes(switchRoutes.login) ? 0 : 1}
                current: location.pathname.includes(switchRoutes.login) ? 0 : 0 }) }),
        React.createElement(Route, { path: '*', element: React.createElement(Navigate, { to: routes.login }) })));
};

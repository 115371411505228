import { getSelectI18nComponents } from '@isyc-react/form/modules/input/select';
import { PersonSex } from '@isyc-react/types';
import { IdentityDocumentType } from 'common/api/types';
import { namespaces } from 'i18n/i18n.constants';
export const Intl = getSelectI18nComponents(namespaces.common);
export const optionsDocumentType = [
    { value: IdentityDocumentType.DNI, label: 'infirmorum.register.documentType.dni' },
    { value: IdentityDocumentType.NIE, label: 'infirmorum.register.documentType.nie' },
    { value: IdentityDocumentType.CIF, label: 'infirmorum.register.documentType.cif' },
    { value: IdentityDocumentType.PASSPORT, label: 'infirmorum.register.documentType.passport' },
    { value: IdentityDocumentType.TIE, label: 'infirmorum.register.documentType.tie' },
    { value: IdentityDocumentType.FOREIGN_DNI, label: 'infirmorum.register.documentType.foreign_dni' },
    { value: IdentityDocumentType.MINOR, label: 'infirmorum.register.documentType.minor' },
];
export const optionsSex = [
    { value: PersonSex.MALE.toString(), label: 'digicare.patientInfo.gender.male', data: 1 },
    { value: PersonSex.FEMALE.toString(), label: 'digicare.patientInfo.gender.female', data: 2 },
];

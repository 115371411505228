import { Spinner } from '@isyc-react/design/bootstrap';
import { DeprecatedDocumentalValidation } from '@isyc-react/newo/modules/pods/deprecated-documental-validation';
import { useDossierUpdateStatusMutationPromise } from '@isyc-react/oc-api/queries/dossier/dossier-update-status';
import { isStudentDossierInvididuallyEnabled } from 'common-app/utilities';
import { namespaces } from 'i18n/i18n.constants';
import { DossierParametersForm } from 'pods/dossier';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const PreregistrationDetailContainer = (props) => {
    const { dossier, reloadDossier } = props;
    const { t } = useTranslation(namespaces.common);
    const [isSending, setIsSending] = React.useState(false);
    const [parametersDone, setParametersDone] = React.useState(true);
    const dossierUpdateStatusPromise = useDossierUpdateStatusMutationPromise();
    React.useEffect(() => {
        setParametersDone(dossier.parameters.filter(x => x.obligatory).every(x => x.value != null && x.value != ""));
    }, []);
    const onClickSend = () => {
        setIsSending(true);
        dossierUpdateStatusPromise({ dossierId: dossier.id, status: { treelevel1: 'INICIO' } })
            .then(dossierData => {
            setIsSending(false);
            if (dossierData.data) {
                reloadDossier();
            }
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "col-md-auto text-center mt-2" },
            React.createElement("span", { className: "badge draft" }, dossier.treeWorkFlowMap.level1.label)),
        React.createElement("p", { className: "text-center font-size-15" }, t('infirmorum.preregistration.title.1')),
        React.createElement(DossierParametersForm, { dossier: dossier, disabled: parametersDone, onSuccessCallback: () => { setParametersDone(true); } }),
        parametersDone &&
            React.createElement(React.Fragment, null,
                React.createElement("h3", { className: "font-size-20 text-secondary fw-600 text-center" }, t('infirmorum.preregistration.documentalTitle.1')),
                React.createElement("p", { className: "text-center font-size-15 mb-1" }, t('infirmorum.preregistration.documentalTitle.2')),
                React.createElement("p", { className: "text-center font-size-15" }, t('infirmorum.preregistration.documentalTitle.3')),
                React.createElement(DeprecatedDocumentalValidation, { dossierId: dossier.id, dossierBusinessUnitId: dossier.businessUnitId, documentalInvididualValidationEnable: (doc) => isStudentDossierInvididuallyEnabled(dossier, doc) })),
        React.createElement("hr", null),
        dossier.treeWorkFlowMap.level1.code === 'BORRADOR' && parametersDone &&
            React.createElement("div", { className: 'row d-flex justify-content-center' },
                React.createElement("button", { className: 'col col-4 btn btn-pill btn-lg btn-primary mx-auto d-block my-5', title: '', disabled: isSending, onClick: (event) => onClickSend() },
                    t('infirmorum.commons.send'),
                    isSending && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' })))));
};

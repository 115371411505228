import { DocumentalTypologyResult } from "@isyc-react/types";
/**
 * Wether the student should be able to edit the invididual documental drop
 * @param dossier
 * @param doc
 * @returns Bool. True if enabled
 */
export const isStudentDossierInvididuallyEnabled = (dossier, doc) => {
    const validStatesArray = [
        "BORRADOR",
        "INICIO",
        "REC_DOC",
        "PEND_MATR",
        "RESERV_PLAZA",
        "FORM_MATR",
        "PAGO_MATR"
    ];
    const validated = doc.documentalTypologyResult !== DocumentalTypologyResult.TYPOLOGY_RESULT_VALID;
    const correctState = validStatesArray.some(x => dossier.treeWorkFlowMap.level1.code === x);
    return validated && correctState;
};

import React from 'react';
import '/assets/css/error.css';
import { SidebarHeaderFooterLayout } from 'layout';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
export const ErrorScene = () => {
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarHeaderFooterLayout, { className: 'error' },
            React.createElement("h1", { className: 'font-size-40 fw-bold text-darker-grey text-center' }, t('infirmorum.error.title')),
            React.createElement("h2", { className: 'text-center' }, t('infirmorum.error.description')))));
};

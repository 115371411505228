import logo from 'assets/img/login-logo.png';
import React from 'react';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import '/assets/css/login.css';
export const LoginLayout = props => {
    const { children } = props;
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'login' },
            React.createElement("main", null,
                React.createElement("div", { className: 'container-fluid min-vh-100 d-flex p-0' },
                    React.createElement("div", { className: 'form col-12 col-lg-5 d-flex align-items-center justify-content-center flex-column p-0' },
                        React.createElement("img", { src: logo, className: 'p-5 img-fluid' }),
                        React.createElement("div", { className: "col-9 col-md-8 my-4" }, children)),
                    React.createElement("div", { className: 'welcome col-lg-7 d-none d-lg-block p-0' },
                        React.createElement("h1", { className: 'text-primary fw-bold font-size-30 mb-0 align-items-center d-flex justify-content-center p-5 text-center' }, t('infirmorum.login.welcome')),
                        React.createElement("div", { className: 'image' })))))));
};

import React from 'react';
import { LoginLayout } from 'layout';
import { LoginForm } from 'pods/login';
import { useParams } from 'react-router-dom';
export const LoginScene = () => {
    const { code } = useParams();
    const buCode = React.useMemo(() => { var _a; return (_a = code === null || code === void 0 ? void 0 : code.toUpperCase()) !== null && _a !== void 0 ? _a : 'SHARE'; }, [code]);
    React.useEffect(() => {
        localStorage.setItem('buCode', buCode);
    }, [buCode]);
    const pictureColors = [
        'bg-blue',
        'bg-green',
        'bg-yellow',
        'bg-orange',
        'bg-red',
        'bg-brown',
        'bg-pink',
        'bg-purple',
        'bg-grey',
    ];
    React.useEffect(() => {
        const random = Math.floor(Math.random() * pictureColors.length);
        localStorage.setItem('pictureColor', pictureColors[random]);
    }, []);
    return (React.createElement(LoginLayout, null,
        React.createElement(LoginForm, { restore: false, onSuccessRedirectInitialLocation: true })));
};

import React from 'react';
import clsx from 'clsx';
import { Footer } from 'pods/footer/footer.component';
import { Header } from 'pods/header/header.component';
import { SidebarComponent } from '../pods/sidebar/sidebar.component';
export const SidebarHeaderFooterLayout = props => {
    const { headerTitle, className, children } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx("infirmorum", className) },
            React.createElement("div", { className: "container-fluid" },
                React.createElement(SidebarComponent, null),
                React.createElement("div", { className: 'main' },
                    React.createElement("div", { className: 'container-fluid mx-auto p-0' },
                        React.createElement(Header, { headerTitle: headerTitle }),
                        React.createElement("div", { className: 'mx-5' },
                            React.createElement("div", { className: 'container my-5 shadow bg-white' },
                                React.createElement("div", { className: 'p-5' }, children)))))),
            React.createElement(Footer, null))));
};

import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { EnrollmentCourseStructure } from '@isyc-react/newo/modules/pods/courses/enrollment-course-structure';
import { useStudentEnrollmentCoursesStructureQuery } from '@isyc-react/newo/modules/common/api/graphql';
import { namespaces } from 'i18n/i18n.constants';
import { SidebarHeaderFooterLayout } from 'layout';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfirmorumEnrollmentCourseStructureCardComponent } from './infirmorum-course-structure-card.component';
export const StudentCoursesScene = () => {
    const { user } = useAuthContext();
    const { t } = useTranslation(namespaces.common);
    const { data, loading, error } = useStudentEnrollmentCoursesStructureQuery({
        variables: {
            user_id: user ? user.id : 0
        },
    });
    return (React.createElement(SidebarHeaderFooterLayout, { headerTitle: t('infirmorum.menu.courses'), className: 'studentCourses' }, (loading || !data) ?
        React.createElement("div", { className: 'd-flex justify-content-center' },
            React.createElement(Spinner, { variant: "primary" })) :
        React.createElement(React.Fragment, null, data.portal_getStudentEnrollmentCoursesStructure.length > 0 ?
            React.createElement(EnrollmentCourseStructure, { data: data.portal_getStudentEnrollmentCoursesStructure, CourseCard: InfirmorumEnrollmentCourseStructureCardComponent })
            :
                React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "mb-0 text-center" }, "No se han encontrado cursos")))));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FormCommonProvider } from '@isyc-react/form/modules/context/form-common';
import { JustifyPosition } from '@isyc-react/form/modules/input/option/base';
import { useForm } from '@isyc-react/form/modules/rhf';
import { useDossierUpdateParametersMutationPromise } from '@isyc-react/oc-api/queries/dossier/dossier-update-parameters';
import { RhfInputParameterGroup } from '@isyc-react/oncustomer/modules/objects/parameter';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const DossierParametersForm = props => {
    const { dossier, disabled, onSuccessCallback } = props;
    const { t } = useTranslation(namespaces.common);
    const updateParameters = useDossierUpdateParametersMutationPromise();
    const form = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {
            parameters: dossier.parameters
        }
    });
    const { control, getValues, watch, handleSubmit } = form;
    const onSuccess = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const dataUpdateParameters = yield updateParameters({
            id: dossier.id,
            parameters: values.parameters.map(x => ({
                id: x.id,
                code: x.code,
                value: x.value,
                label: x.label,
                remarks: x.remarks,
                state: x.state
            }))
        });
        if (dataUpdateParameters.errors) {
            console.log(dataUpdateParameters.errors);
            return;
        }
        onSuccessCallback();
    });
    const onError = (errors) => __awaiter(void 0, void 0, void 0, function* () {
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
            React.createElement(FormCommonProvider, { disabled: disabled },
                React.createElement(RhfInputParameterGroup, { justify: JustifyPosition.CENTER, rhf: { control, name: 'parameters' }, components: {
                        ParameterGroupContainer: (props) => {
                            const { children } = props;
                            return (React.createElement(React.Fragment, null, children));
                        },
                        ParameterContainer: (props) => {
                            const { children, parameter } = props;
                            return (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "row mb-3" },
                                    React.createElement("div", { className: "col-md text-center" },
                                        React.createElement("label", { className: "form-label font-size-20 text-secondary fw-600 mb-3" },
                                            parameter.label,
                                            parameter.obligatory ?
                                                React.createElement(React.Fragment, null,
                                                    React.createElement("span", null, "*"))
                                                : React.createElement(React.Fragment, null)))),
                                React.createElement("div", { className: "row d-flex justify-content-center mb-5" },
                                    React.createElement("div", { className: "col-md-7" }, children))));
                        }
                    }, input: {
                        data: dossier.parameters,
                    } })),
            !disabled &&
                React.createElement("div", { className: 'row d-flex justify-content-center' },
                    React.createElement("button", { type: "submit", className: "col col-4 btn btn-pill btn-lg btn-primary mx-auto d-block my-5" }, t('infirmorum.commons.continue').toUpperCase())))));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuthContext } from '@isyc-react/auth/modules/context';
import { Spinner } from '@isyc-react/design/bootstrap';
import { FormSize } from '@isyc-react/form';
import { NotificationItemType } from '@isyc-react/form/modules/base';
import { useNotificationHubContext } from '@isyc-react/form/modules/context/notification-hub';
import { getCountryFromLang } from '@isyc-react/form/modules/countries';
import { RhfInputDateManager } from '@isyc-react/form/modules/input/manager/date';
import { RhfInputEmailManager } from '@isyc-react/form/modules/input/manager/email';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { RhfInputPhoneManager } from '@isyc-react/form/modules/input/manager/phone';
import { RhfInputSelectManager } from '@isyc-react/form/modules/input/manager/select';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { RhfInputCheckbox } from '@isyc-react/form/modules/input/option';
import { CountryAlpha2Code } from '@isyc-react/types';
import axios from 'axios';
import { IdentityDocumentType } from 'common/api/types';
import { Intl, optionsDocumentType, optionsSex } from 'common/utils';
import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import { nanoid } from 'nanoid';
import { CaptchaContainer } from 'pods/captcha';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '/assets/css/register.css';
export const RegisterForm = props => {
    const { addNotifications } = useNotificationHubContext();
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const form = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {
            documentType: optionsDocumentType.find(o => o.value === IdentityDocumentType.DNI)
        }
    });
    const captchaRef = React.useRef(null);
    const { control, getValues, watch, handleSubmit } = form;
    const persistForm = (values) => __awaiter(void 0, void 0, void 0, function* () {
        if (captchaRef.current) {
            setLoading(true);
            axios
                .post('/api/public/register/create', {
                username: values.username,
                password: values.password,
                repeatedPassword: values.repeatedPassword,
                name: values.name,
                surname1: values.surname1,
                surname2: values.surname2,
                documentType: values.documentType.value,
                document: values.document,
                email: values.email,
                phone: values.phone.number,
                captcha: {
                    imgPath: '',
                    captchaId: captchaRef.current.captchaId,
                    response: values.captcha.response,
                },
                sex: values.sex.data,
                birthDate: values.birthDate,
                generalConditions: checked
            })
                .then(r => {
                addNotifications([
                    {
                        key: nanoid(),
                        message: 'Registro completado',
                        persist: true,
                        type: NotificationItemType.SUCCESS
                    },
                ]);
                navigate(routes.login);
            })
                .catch((r) => {
                var _a;
                const data = (_a = r.response) === null || _a === void 0 ? void 0 : _a.data;
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        addNotifications([
                            {
                                key: nanoid(),
                                message: value,
                                persist: true,
                                type: NotificationItemType.ERROR
                            },
                        ]);
                    }
                }
                captchaRef.current && captchaRef.current.updateCaptcha();
            })
                .finally(() => {
                setLoading(false);
            });
        }
    });
    const onSuccess = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield persistForm(values);
    });
    const onError = (errors) => __awaiter(void 0, void 0, void 0, function* () { });
    const defaultLanguage = useDefaultCountryCode('ES');
    const [checked, setChecked] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("main", null,
            React.createElement("div", { className: "container mx-auto p-4 p-md-0" },
                React.createElement("h2", { className: "text-primary font-size-32 mt-0 mt-md-5" }, t('infirmorum.register.title')),
                React.createElement("p", { className: "font-size-16" }, t('infirmorum.register.title2')),
                React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
                    React.createElement("div", { className: "row mb-4 mt-5" },
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'name', rules: { required: true } }, input: {
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.name'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: t('infirmorum.register.placeholder.name'),
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'surname1', rules: { required: true } }, input: {
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.surname1'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: t('infirmorum.register.placeholder.surname1'),
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'surname2' }, input: {
                                    label: t('infirmorum.register.surname2'),
                                    placeholder: t('infirmorum.register.placeholder.surname2'),
                                } }))),
                    React.createElement("div", { className: 'row mb-4 mt-5' },
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputSelectManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                                    name: 'documentType',
                                    rules: { required: true },
                                    control,
                                }, input: {
                                    size: FormSize.LG,
                                    label: t('infirmorum.register.documentType'),
                                    options: optionsDocumentType,
                                    isClearable: false,
                                    components: Intl,
                                } })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'document', rules: { required: true } }, input: {
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.document'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: t('infirmorum.register.placeholder.document')
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputEmailManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'email', rules: { required: true } }, input: {
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.email'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: t('infirmorum.register.placeholder.email')
                                }, errorMessage: t('infirmorum.register.fieldreq') }))),
                    React.createElement("div", { className: 'row mb-4 mt-5' },
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputPhoneManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                                    name: 'phone',
                                    rules: { required: true },
                                    control,
                                }, input: {
                                    size: FormSize.LG,
                                    defaultCountry: defaultLanguage,
                                    label: t('infirmorum.register.phone'),
                                    placeholder: t('infirmorum.register.placeholder.phone')
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputSelectManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                                    name: 'sex',
                                    rules: { required: true },
                                    control,
                                }, input: {
                                    size: FormSize.LG,
                                    label: 'Género',
                                    options: optionsSex,
                                    isClearable: false,
                                    components: Intl,
                                } })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputDateManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                                    name: 'birthDate',
                                    control,
                                    rules: {
                                        required: true,
                                    },
                                }, input: {
                                    size: FormSize.LG,
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.birthDate'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: "DD/MM/YYYY"
                                } }))),
                    React.createElement("div", { className: 'row mb-4 mt-5' },
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'username', rules: { required: true } }, input: {
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.username'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                    placeholder: t('infirmorum.register.placeholder.username')
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'password', rules: { required: true } }, input: {
                                    placeholder: t('infirmorum.register.placeholder.password'),
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.password'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                }, errorMessage: t('infirmorum.register.fieldreq') })),
                        React.createElement("div", { className: "col-12 col-xl-4 mb-3" },
                            React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                                    control, name: 'repeatedPassword',
                                    rules: {
                                        required: true,
                                        validate: {
                                            checkPassword: (value) => {
                                                return getValues('password') === value ? true : false;
                                            }
                                        }
                                    }
                                }, input: {
                                    placeholder: t('infirmorum.register.placeholder.repeatedPassword'),
                                    label: (React.createElement(React.Fragment, null,
                                        t('infirmorum.register.repeatedPassword'),
                                        ' ',
                                        true && React.createElement("span", { className: 'required' }, "*"))),
                                }, errorMessage: t('infirmorum.register.fieldreq') }))),
                    React.createElement("p", { className: "font-size-13 text-dark-secondary help" }, t('infirmorum.register.passwordreq')),
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement(RhfInputCheckbox, { rhf: {
                                control, name: "generalConditions", rules: {
                                    required: true, onChange() {
                                        if (!checked) {
                                            setChecked(true);
                                        }
                                        else {
                                            setChecked(false);
                                        }
                                    },
                                }
                            }, input: {
                                label: (React.createElement(React.Fragment, null,
                                    React.createElement("div", null,
                                        React.createElement("label", null,
                                            "He le\u00EDdo y acepto los t\u00E9rminos y condiciones de la ",
                                            React.createElement("a", { href: "https://saluscampusdemadrid.com/politica-de-privacidad/", className: "text-primary border-bottom border-primary" }, "Pol\u00EDtica de Privacidad"))))),
                                size: FormSize.SM,
                            } })),
                    React.createElement("div", { className: "col-md-12" },
                        React.createElement(CaptchaContainer, { control: control, ref: captchaRef })),
                    React.createElement("div", { className: "mb-5" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-12 col-lg-4 d-flex justify-content-center justify-content-lg-start" },
                                React.createElement("button", { type: "button", className: "btn btn-link btn-lg d-block mt-4", onClick: () => navigate(routes.login) }, t('infirmorum.register.tologin'))),
                            React.createElement("div", { className: "col-12 col-lg-4 d-flex justify-content-center" },
                                React.createElement("button", { type: 'submit', className: 'btn btn-lg btn-primary d-block px-5 fw-bold py-3', disabled: loading || !checked },
                                    t('infirmorum.register.register'),
                                    loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: 'sm' }))),
                            React.createElement("div", { className: "col-12 col-lg-4" }))))))));
};
export const useDefaultCountryCode = (defaultCountry) => {
    const { user } = useAuthContext();
    if (user && user.language) {
        const country = getCountryFromLang(user.language);
        if (country)
            return CountryAlpha2Code[country];
    }
    //TODO: Mirar el del navegador (libreria)
    return CountryAlpha2Code[defaultCountry.toUpperCase()];
};
